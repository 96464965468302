import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'rectangle-rounded-top',
  'IconRectangleRoundedTop',
  [
    [
      'path',
      {
        d: 'M9 6h6a6 6 0 0 1 6 6v5a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-5a6 6 0 0 1 6 -6z',
        key: 'svg-0',
      },
    ],
  ],
);
