import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-bag-discount',
  'IconShoppingBagDiscount',
  [
    [
      'path',
      {
        d: 'M12.5 21h-3.926a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304h11.339a2 2 0 0 1 1.977 2.304l-.416 2.7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 11v-5a3 3 0 0 1 6 0v5', key: 'svg-1' }],
    ['path', { d: 'M16 21l5 -5', key: 'svg-2' }],
    ['path', { d: 'M21 21v.01', key: 'svg-3' }],
    ['path', { d: 'M16 16v.01', key: 'svg-4' }],
  ],
);
