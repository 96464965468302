import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-share',
  'IconShoppingCartShare',
  [
    ['path', { d: 'M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M12.5 17h-6.5v-14h-2', key: 'svg-1' }],
    ['path', { d: 'M6 5l14 1l-1 7h-13', key: 'svg-2' }],
    ['path', { d: 'M16 22l5 -5', key: 'svg-3' }],
    ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-4' }],
  ],
);
