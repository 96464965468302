import createReactComponent from '../createReactComponent';
export default createReactComponent('time-duration-60', 'IconTimeDuration60', [
  [
    'path',
    { d: 'M14 10.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M11 9h-2a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0', key: 'svg-2' }],
]);
