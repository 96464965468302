import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-bag-exclamation',
  'IconShoppingBagExclamation',
  [
    [
      'path',
      {
        d: 'M15 21h-6.426a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304h11.339a2 2 0 0 1 1.977 2.304l-.258 1.678',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 11v-5a3 3 0 0 1 6 0v5', key: 'svg-1' }],
    ['path', { d: 'M19 16v3', key: 'svg-2' }],
    ['path', { d: 'M19 22v.01', key: 'svg-3' }],
  ],
);
