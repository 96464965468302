import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'rewind-forward-15',
  'IconRewindForward15',
  [
    ['path', { d: 'M17 9l3 -3l-3 -3', key: 'svg-0' }],
    ['path', { d: 'M9 18a6 6 0 1 1 0 -12h11', key: 'svg-1' }],
    [
      'path',
      { d: 'M16 20h2a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-2v-3h3', key: 'svg-2' },
    ],
    ['path', { d: 'M13 14v6', key: 'svg-3' }],
  ],
);
