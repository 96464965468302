import createReactComponent from '../createReactComponent';
export default createReactComponent('sphere-off', 'IconSphereOff', [
  [
    'path',
    {
      d: 'M3 12c0 1.657 4.03 3 9 3c.987 0 1.936 -.053 2.825 -.15m3.357 -.67c1.735 -.547 2.818 -1.32 2.818 -2.18',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20.051 16.027a9 9 0 0 0 -12.083 -12.075m-2.34 1.692a9 9 0 0 0 12.74 12.716',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
