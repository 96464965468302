import createReactComponent from '../createReactComponent';
export default createReactComponent('pencil-search', 'IconPencilSearch', [
  [
    'path',
    {
      d: 'M17 11l1.5 -1.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4l3 -3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13.5 6.5l4 4', key: 'svg-1' }],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-3' }],
]);
