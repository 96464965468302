import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-pin',
  'IconShoppingCartPin',
  [
    ['path', { d: 'M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M12 17h-6v-14h-2', key: 'svg-1' }],
    ['path', { d: 'M6 5l14 1l-.716 5.011m-5.284 1.989h-8', key: 'svg-2' }],
    [
      'path',
      {
        d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
        key: 'svg-3',
      },
    ],
    ['path', { d: 'M19 18v.01', key: 'svg-4' }],
  ],
);
