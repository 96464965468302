import createReactComponent from '../createReactComponent';
export default createReactComponent('pyramid-plus', 'IconPyramidPlus', [
  [
    'path',
    {
      d: 'M18.719 11.985l-5.889 -9.539a.999 .999 0 0 0 -1.664 0l-8.54 13.836a1.005 1.005 0 0 0 .386 1.452l8.092 4.054a1.994 1.994 0 0 0 1.789 0l.149 -.074',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 2v20', key: 'svg-1' }],
  ['path', { d: 'M16 19h6', key: 'svg-2' }],
  ['path', { d: 'M19 16v6', key: 'svg-3' }],
]);
