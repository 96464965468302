import createReactComponent from '../createReactComponent';
export default createReactComponent('pencil-pin', 'IconPencilPin', [
  [
    'path',
    {
      d: 'M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13.5 6.5l4 4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-3' }],
]);
