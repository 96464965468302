import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'rewind-backward-5',
  'IconRewindBackward5',
  [
    ['path', { d: 'M15 18a6 6 0 1 0 0 -12h-11', key: 'svg-0' }],
    ['path', { d: 'M7 9l-3 -3l3 -3', key: 'svg-1' }],
    [
      'path',
      { d: 'M8 20h2a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-2v-3h3', key: 'svg-2' },
    ],
  ],
);
