import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-exclamation',
  'IconShoppingCartExclamation',
  [
    ['path', { d: 'M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M15 17h-9v-14h-2', key: 'svg-1' }],
    ['path', { d: 'M6 5l14 1l-.854 5.976m-2.646 1.024h-10.5', key: 'svg-2' }],
    ['path', { d: 'M19 16v3', key: 'svg-3' }],
    ['path', { d: 'M19 22v.01', key: 'svg-4' }],
  ],
);
