import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-dollar',
  'IconShoppingCartDollar',
  [
    ['path', { d: 'M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M13 17h-7v-14h-2', key: 'svg-1' }],
    ['path', { d: 'M6 5l14 1l-.575 4.022m-4.925 2.978h-8.5', key: 'svg-2' }],
    [
      'path',
      {
        d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
        key: 'svg-3',
      },
    ],
    ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-4' }],
  ],
);
