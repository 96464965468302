import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'time-duration-off',
  'IconTimeDurationOff',
  [
    ['path', { d: 'M3 12v.01', key: 'svg-0' }],
    ['path', { d: 'M7.5 19.8v.01', key: 'svg-1' }],
    ['path', { d: 'M4.2 16.5v.01', key: 'svg-2' }],
    ['path', { d: 'M4.2 7.5v.01', key: 'svg-3' }],
    [
      'path',
      {
        d: 'M12 21a8.994 8.994 0 0 0 6.362 -2.634m1.685 -2.336a9 9 0 0 0 -8.047 -13.03',
        key: 'svg-4',
      },
    ],
    ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
  ],
);
