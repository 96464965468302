import createReactComponent from '../createReactComponent';
export default createReactComponent('play-football', 'IconPlayFootball', [
  ['path', { d: 'M11 4a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M3 17l5 1l.75 -1.5', key: 'svg-1' }],
  ['path', { d: 'M14 21v-4l-4 -3l1 -6', key: 'svg-2' }],
  ['path', { d: 'M6 12v-3l5 -1l3 3l3 1', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M19.5 20a.5 .5 0 1 0 0 -1a.5 .5 0 0 0 0 1z',
      fill: 'currentColor',
      key: 'svg-4',
    },
  ],
]);
