import createReactComponent from '../createReactComponent';
export default createReactComponent('time-duration-30', 'IconTimeDuration30', [
  [
    'path',
    { d: 'M14 10.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M8 9h1.5a1.5 1.5 0 0 1 0 3h-.5h.5a1.5 1.5 0 0 1 0 3h-1.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 12v.01', key: 'svg-2' }],
  ['path', { d: 'M7.5 4.2v.01', key: 'svg-3' }],
  ['path', { d: 'M7.5 19.8v.01', key: 'svg-4' }],
  ['path', { d: 'M4.2 16.5v.01', key: 'svg-5' }],
  ['path', { d: 'M4.2 7.5v.01', key: 'svg-6' }],
  ['path', { d: 'M12 21a9 9 0 0 0 0 -18', key: 'svg-7' }],
]);
