import createReactComponent from '../createReactComponent';
export default createReactComponent('table-row', 'IconTableRow', [
  [
    'path',
    {
      d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 3l-6 6', key: 'svg-1' }],
  ['path', { d: 'M14 3l-7 7', key: 'svg-2' }],
  ['path', { d: 'M19 3l-7 7', key: 'svg-3' }],
  ['path', { d: 'M21 6l-4 4', key: 'svg-4' }],
  ['path', { d: 'M3 10h18', key: 'svg-5' }],
  ['path', { d: 'M10 10v11', key: 'svg-6' }],
]);
