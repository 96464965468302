import createReactComponent from '../createReactComponent';
export default createReactComponent('row-remove', 'IconRowRemove', [
  [
    'path',
    {
      d: 'M20 6v4a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 16l4 4', key: 'svg-1' }],
  ['path', { d: 'M10 20l4 -4', key: 'svg-2' }],
]);
