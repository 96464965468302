import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-question',
  'IconShoppingCartQuestion',
  [
    ['path', { d: 'M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M13.5 17h-7.5v-14h-2', key: 'svg-1' }],
    ['path', { d: 'M6 5l14 1l-.714 5m-4.786 2h-8.5', key: 'svg-2' }],
    ['path', { d: 'M19 22v.01', key: 'svg-3' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-4',
      },
    ],
  ],
);
