import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-bolt',
  'IconShoppingCartBolt',
  [
    ['path', { d: 'M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M13.5 17h-7.5v-14h-2', key: 'svg-1' }],
    ['path', { d: 'M6 5l14 1l-.858 6.004m-2.642 .996h-10.5', key: 'svg-2' }],
    ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-3' }],
  ],
);
