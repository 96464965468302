import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'rectangle-rounded-bottom',
  'IconRectangleRoundedBottom',
  [
    [
      'path',
      {
        d: 'M9 18h6a6 6 0 0 0 6 -6v-5a1 1 0 0 0 -1 -1h-16a1 1 0 0 0 -1 1v5a6 6 0 0 0 6 6z',
        key: 'svg-0',
      },
    ],
  ],
);
