import createReactComponent from '../createReactComponent';
export default createReactComponent('skateboarding', 'IconSkateboarding', [
  ['path', { d: 'M16 4a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M5.5 15h3.5l.75 -1.5', key: 'svg-1' }],
  ['path', { d: 'M14 19v-5l-2.5 -3l2.5 -4', key: 'svg-2' }],
  ['path', { d: 'M8 8l3 -1h4l1 3h3', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M17.5 21a.5 .5 0 1 0 0 -1a.5 .5 0 0 0 0 1z',
      fill: 'currentColor',
      key: 'svg-4',
    },
  ],
  [
    'path',
    { d: 'M3 18c0 .552 .895 1 2 1h14c1.105 0 2 -.448 2 -1', key: 'svg-5' },
  ],
  [
    'path',
    {
      d: 'M6.5 21a.5 .5 0 1 0 0 -1a.5 .5 0 0 0 0 1z',
      fill: 'currentColor',
      key: 'svg-6',
    },
  ],
]);
