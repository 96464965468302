import createReactComponent from '../createReactComponent';
export default createReactComponent('sphere-plus', 'IconSpherePlus', [
  [
    'path',
    {
      d: 'M3 12c0 1.657 4.03 3 9 3c1.116 0 2.185 -.068 3.172 -.192m5.724 -2.35a1.1 1.1 0 0 0 .104 -.458',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M20.984 12.546a9 9 0 1 0 -8.442 8.438', key: 'svg-1' }],
  ['path', { d: 'M16 19h6', key: 'svg-2' }],
  ['path', { d: 'M19 16v6', key: 'svg-3' }],
]);
