import createReactComponent from '../createReactComponent';
export default createReactComponent('time-duration-45', 'IconTimeDuration45', [
  [
    'path',
    { d: 'M13 15h2a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-2v-3h3', key: 'svg-0' },
  ],
  ['path', { d: 'M7 9v2a1 1 0 0 0 1 1h1', key: 'svg-1' }],
  ['path', { d: 'M10 9v6', key: 'svg-2' }],
  ['path', { d: 'M7.5 4.2v.01', key: 'svg-3' }],
  ['path', { d: 'M4.2 7.5v.01', key: 'svg-4' }],
  ['path', { d: 'M3 12a9 9 0 1 0 9 -9', key: 'svg-5' }],
]);
