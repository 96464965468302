import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'rewind-backward-40',
  'IconRewindBackward40',
  [
    [
      'path',
      { d: 'M19.007 16.466a6 6 0 0 0 -4.007 -10.466h-11', key: 'svg-0' },
    ],
    [
      'path',
      {
        d: 'M12 15.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M6 14v2a1 1 0 0 0 1 1h1', key: 'svg-2' }],
    ['path', { d: 'M9 14v6', key: 'svg-3' }],
    ['path', { d: 'M7 9l-3 -3l3 -3', key: 'svg-4' }],
  ],
);
