import createReactComponent from '../createReactComponent';
export default createReactComponent('pencil-star', 'IconPencilStar', [
  [
    'path',
    {
      d: 'M17.5 10.5l1 -1a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4l2 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13.5 6.5l4 4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z',
      key: 'svg-2',
    },
  ],
]);
