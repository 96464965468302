import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-check',
  'IconShoppingCartCheck',
  [
    ['path', { d: 'M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M11.5 17h-5.5v-14h-2', key: 'svg-1' }],
    ['path', { d: 'M6 5l14 1l-1 7h-13', key: 'svg-2' }],
    ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-3' }],
  ],
);
