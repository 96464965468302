import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-plus',
  'IconShoppingCartPlus',
  [
    ['path', { d: 'M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M12.5 17h-6.5v-14h-2', key: 'svg-1' }],
    ['path', { d: 'M6 5l14 1l-.86 6.017m-2.64 .983h-10.5', key: 'svg-2' }],
    ['path', { d: 'M16 19h6', key: 'svg-3' }],
    ['path', { d: 'M19 16v6', key: 'svg-4' }],
  ],
);
