import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-cart-search',
  'IconShoppingCartSearch',
  [
    ['path', { d: 'M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M11 17h-5v-14h-2', key: 'svg-1' }],
    ['path', { d: 'M6 5l14 1l-.718 5.023m-6.282 1.977h-7', key: 'svg-2' }],
    ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-3' }],
    ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-4' }],
  ],
);
