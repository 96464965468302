import createReactComponent from '../createReactComponent';
export default createReactComponent('pencil-cancel', 'IconPencilCancel', [
  [
    'path',
    {
      d: 'M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13.5 6.5l4 4', key: 'svg-1' }],
  ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M17 21l4 -4', key: 'svg-3' }],
]);
