import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'rewind-forward-40',
  'IconRewindForward40',
  [
    ['path', { d: 'M5.007 16.478a6 6 0 0 1 3.993 -10.478h11', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M15 15.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M17 9l3 -3l-3 -3', key: 'svg-2' }],
    ['path', { d: 'M9 14v2a1 1 0 0 0 1 1h1', key: 'svg-3' }],
    ['path', { d: 'M12 14v6', key: 'svg-4' }],
  ],
);
