import createReactComponent from '../createReactComponent';
export default createReactComponent('time-duration-15', 'IconTimeDuration15', [
  [
    'path',
    { d: 'M12 15h2a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-2v-3h3', key: 'svg-0' },
  ],
  ['path', { d: 'M9 9v6', key: 'svg-1' }],
  ['path', { d: 'M3 12v.01', key: 'svg-2' }],
  ['path', { d: 'M12 21v.01', key: 'svg-3' }],
  ['path', { d: 'M7.5 4.2v.01', key: 'svg-4' }],
  ['path', { d: 'M16.5 19.8v.01', key: 'svg-5' }],
  ['path', { d: 'M7.5 19.8v.01', key: 'svg-6' }],
  ['path', { d: 'M4.2 16.5v.01', key: 'svg-7' }],
  ['path', { d: 'M19.8 16.5v.01', key: 'svg-8' }],
  ['path', { d: 'M4.2 7.5v.01', key: 'svg-9' }],
  ['path', { d: 'M21 12a9 9 0 0 0 -9 -9', key: 'svg-10' }],
]);
