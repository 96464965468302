import createReactComponent from '../createReactComponent';
export default createReactComponent('time-duration-90', 'IconTimeDuration90', [
  [
    'path',
    {
      d: 'M8 14.25c0 .414 .336 .75 .75 .75h1.5a.75 .75 0 0 0 .75 -.75v-4.5a.75 .75 0 0 0 -.75 -.75h-1.5a.75 .75 0 0 0 -.75 .75v1.5c0 .414 .336 .75 .75 .75h2.25',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M14 10.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z', key: 'svg-1' },
  ],
  ['path', { d: 'M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0', key: 'svg-2' }],
]);
