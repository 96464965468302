import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'rewind-backward-10',
  'IconRewindBackward10',
  [
    ['path', { d: 'M7 9l-3 -3l3 -3', key: 'svg-0' }],
    [
      'path',
      { d: 'M15.997 17.918a6.002 6.002 0 0 0 -.997 -11.918h-11', key: 'svg-1' },
    ],
    ['path', { d: 'M6 14v6', key: 'svg-2' }],
    [
      'path',
      { d: 'M9 15.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z', key: 'svg-3' },
    ],
  ],
);
