import createReactComponent from '../createReactComponent';
export default createReactComponent('shopping-bag-x', 'IconShoppingBagX', [
  [
    'path',
    {
      d: 'M13 21h-4.426a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304h11.339a2 2 0 0 1 1.977 2.304l-.506 3.287',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 11v-5a3 3 0 0 1 6 0v5', key: 'svg-1' }],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-2' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-3' }],
]);
