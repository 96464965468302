import createReactComponent from '../createReactComponent';
export default createReactComponent('pick', 'IconPick', [
  [
    'path',
    {
      d: 'M13 8l-9.383 9.418a2.091 2.091 0 0 0 0 2.967a2.11 2.11 0 0 0 2.976 0l9.407 -9.385',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 3h4.586a1 1 0 0 1 .707 .293l6.414 6.414a1 1 0 0 1 .293 .707v4.586a2 2 0 1 1 -4 0v-3l-5 -5h-3a2 2 0 1 1 0 -4z',
      key: 'svg-1',
    },
  ],
]);
