import createReactComponent from '../createReactComponent';
export default createReactComponent('scoreboard', 'IconScoreboard', [
  [
    'path',
    {
      d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 5v2', key: 'svg-1' }],
  ['path', { d: 'M12 10v1', key: 'svg-2' }],
  ['path', { d: 'M12 14v1', key: 'svg-3' }],
  ['path', { d: 'M12 18v1', key: 'svg-4' }],
  ['path', { d: 'M7 3v2', key: 'svg-5' }],
  ['path', { d: 'M17 3v2', key: 'svg-6' }],
  [
    'path',
    { d: 'M15 10.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z', key: 'svg-7' },
  ],
  [
    'path',
    {
      d: 'M6 9h1.5a1.5 1.5 0 0 1 0 3h-.5h.5a1.5 1.5 0 0 1 0 3h-1.5',
      key: 'svg-8',
    },
  ],
]);
