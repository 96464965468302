import createReactComponent from '../createReactComponent';
export default createReactComponent('prism-off', 'IconPrismOff', [
  ['path', { d: 'M12 12v10', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17.957 17.952l-4.937 3.703a1.7 1.7 0 0 1 -2.04 0l-5.98 -4.485a2.5 2.5 0 0 1 -1 -2v-11.17m3 -1h12a1 1 0 0 1 1 1v11.17c0 .25 -.037 .495 -.109 .729',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M12.688 8.7a1.7 1.7 0 0 0 .357 -.214l6.655 -5.186', key: 'svg-2' },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
