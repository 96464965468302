import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-bag-search',
  'IconShoppingBagSearch',
  [
    [
      'path',
      {
        d: 'M11.5 21h-2.926a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304h11.339a2 2 0 0 1 1.977 2.304l-.117 .761',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 11v-5a3 3 0 0 1 6 0v5', key: 'svg-1' }],
    ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
    ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-3' }],
  ],
);
