import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'shopping-bag-edit',
  'IconShoppingBagEdit',
  [
    [
      'path',
      {
        d: 'M11 21h-2.426a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304h11.339a2 2 0 0 1 1.977 2.304l-.109 .707',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 11v-5a3 3 0 0 1 6 0v5', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z',
        key: 'svg-2',
      },
    ],
  ],
);
